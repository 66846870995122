/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useState } from "react";
import { Snackbar } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import useIsDesktop from "hooks/useIsDesktop";
import Toast from "components/ToastComponent";

export const ToastContext = createContext();

export const ToastContextProvider = ({ children }) => {
	const [openToast, setOpenToast] = useState(false);
	const [toastType, setToastType] = useState(false);
	const [toastMessageHeading, setToastMessageHeading] = useState("");
	const [btnText, setBtnText] = useState("");
	const [btnClickFunc, setBtnClickFunc] = useState({ btnOnClick: () => {} });
	const [hideClose, setHideClose] = useState(false);
	const [position, setPosition] = useState({});

	const isDesktopView = useIsDesktop();
	const randomUid = uuidv4();

	const DEFAULT_TOAST_POSITION = {
		vertical: "bottom",
		horizontal: isDesktopView ? "right" : "center",
	};

	const handleOpenToast = ({
		heading = "",
		type = "success",
		buttonText = "",
		onBtnClick = () => {},
		hideCloseBtn = false,
		vertical,
		horizontal,
	}) => {
		setOpenToast(true);
		setToastMessageHeading(heading);
		setToastType(type);
		setBtnText(buttonText);
		setHideClose(hideCloseBtn);
		setBtnClickFunc({ btnOnClick: onBtnClick });
		setPosition({
			vertical: vertical || DEFAULT_TOAST_POSITION.vertical,
			horizontal: horizontal || DEFAULT_TOAST_POSITION.horizontal,
		});
	};

	const handleCloseToast = () => {
		setOpenToast(false);
		setToastMessageHeading("");
		setBtnText("");
		setBtnClickFunc({ btnOnClick: () => {} });
		setHideClose(false);
		setPosition({});
	};

	return (
		<ToastContext.Provider
			value={{
				toastMessageHeading,
				openToast,
				handleOpenToast,
				handleCloseToast,
			}}
		>
			{children}
			<Snackbar
				key={randomUid}
				open={openToast}
				autoHideDuration={6000}
				onClose={handleCloseToast}
				anchorOrigin={{
					vertical: position.vertical || DEFAULT_TOAST_POSITION.vertical,
					horizontal: position.horizontal || DEFAULT_TOAST_POSITION.horizontal,
				}}
			>
				<Toast
					heading={toastMessageHeading}
					onClose={handleCloseToast}
					type={toastType}
					buttonText={btnText}
					onBtnClick={btnClickFunc?.btnOnClick}
					hideCloseBtn={hideClose}
				/>
			</Snackbar>
		</ToastContext.Provider>
	);
};
