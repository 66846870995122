const VALUE_TO_LABEL_MAPPING = {
	work_mode: {
		wfh: "Work from home",
		wfo: "Work from office",
		wff: "Work from field",
	},

	work_type: {
		full_time: "Full time",
		part_time: "Part time",
		internship: "Internship",
	},

	work_shift: {
		day_shift: "Day shift",
		night_shift: "Night shift",
	},
	posted_in: {
		24: "Last 24 hours",
		72: "Last 3 days",
		168: "Last 7 days",
	},
	gender: {
		"gender-f": "Female",
		"gender-m": "Male",
		"gender-o": "Other",
	},
	sort_by: {
		"-max_salary": "Salary - High to low",
		"-max_distance": "Distance - Near to far",
		"-last_updated": "Date posted - New to Old",
	},
	english_level: {
		"No English": "No English",
		"Basic English": "Basic English",
		"Intermediate English": "Intermediate English",
		"Advanced English": "Advanced English",
	},
	education_level: {
		1: "10 or Below 10th",
		2: "12th Pass",
		3: "Diploma",
		4: "ITI",
		5: "Graduate",
		6: "Post Graduate",
	},
	min_experience: {
		"-1": "Any",
		"0": "Fresher",
		"1": "1 year",
		"2": "2 years",
		"3": "3 years",
		"4": "4 years",
		"5": "5 years",
		"6": "6 years",
		"7": "7 years",
		"8": "8 years",
		"9": "9 years",
		"10": "10 years",
		"11": "11 years",
		"12": "12 years",
		"13": "13 years",
		"14": "14 years",
		"15": "15 years",
		"16": "16 years",
		"17": "17 years",
		"18": "18 years",
		"19": "19 years",
		"20": "20 years",
		"21": "21 years",
		"22": "22 years",
		"23": "23 years",
		"24": "24 years",
		"25": "25 years",
		"26": "26 years",
		"27": "27 years",
		"28": "28 years",
		"29": "29 years",
		"30": "30 years",
		"31": "31 years",
	},
	salary: {
		"0": "₹0",
		"10000": "₹10,000",
		"20000": "₹20,000",
		"30000": "₹30,000",
		"40000": "₹40,000",
		"50000": "₹50,000",
		"60000": "₹60,000",
		"70000": "₹70,000",
		"80000": "₹80,000",
		"90000": "₹90,000",
		"100000": "₹1 Lakh",
		"110000": "₹1.1 Lakhs",
		"120000": "₹1.2 Lakhs",
		"130000": "₹1.3 lakhs",
		"140000": "₹1.4 Lakhs",
		"150000": "₹1.5 Lakhs",
	},
	distance: {
		"": "All",
		"lt-5": "Within 5 km",
		"lt-10": "Within 10 km",
		"lt-20": "Within 20 km",
		"lt-50": "Within 50 km",
	},
	featured_jobs: "Featured jobs",
};
const MIN_EXPERIENCE_DEFAULT_VALUE = 32;
const POSTED_IN_DEFAULT_VALUE = -1;
const DISTANCE_DEFAULT_VALUE = "All";
const PRE_APPLIED_FILTERS_FREQUENCY_KEY = "PRE_APPLIED_FILTERS_FREQUENCY";

export {
	VALUE_TO_LABEL_MAPPING,
	MIN_EXPERIENCE_DEFAULT_VALUE,
	PRE_APPLIED_FILTERS_FREQUENCY_KEY,
	POSTED_IN_DEFAULT_VALUE,
	DISTANCE_DEFAULT_VALUE,
};
