export default {
	PUBLIC_URL: "/api/publicapis/v1/users/",
	PUBLIC_URL_CITIES: "/api/publicapis/v1/city-names/",
	PUBLIC_URL_CITY_AREAS: "/api/publicapis/v1/city-area-names/",
	PUBLIC_URL_JOB_CATEGORIES: "/api/publicapis/v1/job-category-names/",
	PUBLIC_URL_POST: "/api/publicapis/v1/posts/",
	PUBLIC_URL_POST_HEADER: "/api/publicapis/v1/get-post-header/",
	PUBLIC_URL_JOB: "/api/publicapis/v1/jobs/",
	PUBLIC_URL_JOB_V7: "/api/jobfeed/v7/jobs/",
	PUBLIC_URL_COMPANIES_LIST: "/api/publicapis/v1/companies-list/",
	PUBLIC_URL_JOB_APPLICATIONS: "/api/publicapis/v1/job-applications/",
	PUBLIC_URL_JOBS_V2: "/api/publicapis/v2/jobs/",
	PUBLIC_URL_JOBS_WRAPPER: "/user-profile-orchestrator/public/v1/jobs/",
	EMAIL_APPLICATION_SUBMSSION: "/amaterasu/api/v1/email/application/",
	USER_JOBS_HORIZONTAL: "/api/v7/employee-jobs-horizontal/",
	USER_AREAS_IN_CITY: "/api/location/v1/cities/{cityId}/areas/",
	PUBLIC_URL_FOOTER: "/api/publicapis/v1/footer/",
	PUBLIC_URL_CONTENT: "/api/publicapis/v1/contents/",
	PUBLIC_ORGANIZATION_URL: "/api/publicapis/v1/organizations/",
	PUBLIC_URL_GROUPS: "/cerebro/api/v1/public/search-candidates/",
	PUBLIC_URL_CATEGORIES: "/api/publicapis/v1/job-category-list/",
	AUTH_CATEGORIES: "/api/v1/category/titles/",
	PUBLIC_WHITE_PAPER_DOWNLOAD: "/api/publicapis/v1/whitepaper-download/",
	PUBLIC_SUBMIT_LEAD: "/api/publicapis/v1/city-leads",
	PUBLIC_OTHER_CITIES_LEAD: "/api/publicapis/v1/city-leads",
	PUBLIC_URL_GROUP_POST: "/api/publicapis/v1/post/",
	PUBLIC_URL_TOP_USERS: "/api/publicapis/v1/get-top-users/",
	PUBLIC_URL_GROUP_POST_LIST: "/api/publicapis/v1/get-titled-post/",
	PUBLIC_URL_CATEGORY_FROM_TYPEID: "/api/publicapis/v1/get-job-category-by-title-id",
	PUBLIC_FREE_JOBS_ALERT: "/api/publicapis/v1/free-job-alert/",
	REQUEST_OTP: "/api/userprofile/v1/otp/",
	LOGIN: "/apna-auth/otp/login/",
	LOGOUT: "/auth/v2/logout",
	UPDATE_USER_PROFILE: "/api/userprofile/v2/user/",
	UPDATE_USER_PROFILE_V3: "/api/userprofile/v3/user",
	UPDATE_USER_INTERESTS: "/api/userprofile/v1/interests/",
	CITY_LIST_URL: "/api/location/v1/cities/",
	JOB_TITLE_LIST_URL: "/api/v1/job-titles/?approved=true",
	EDUCATION_LEVEL_URL: "/api/v2/education-levels/",
	SUPERCATEGORIES_URL: "/api/userprofile/v1/supercategories/",
	ORG_SUGGESTIONS_URL: "/apna-organisations/api/v1/organisation-search",
	JOB_TITLE_SUGGESTIONS: "/api/job-titles/?title={title}",
	UNIVERSITY_SUGGESTIONS: "/apna-organisations/api/v1/organisation-search",
	COMAPANY_SUGGESTIONS: "/apna-organisations/api/v1/organisation-search",
	DEGREE_SUGGESTIONS: "/api/v1/degrees/search/",
	CREATE_DEGREE: "/api/v1/degrees/",
	USER_PROFILE_URL: "api/userprofile/v2/user/{user}/?lang=",
	VI_USER_LOGIN: "/partner/v1/vi/login/",
	PROFILE_USER_URL: "/user-profile/api/v1/profile/by-slug/",
	PROFILE_USER_PRIVATE_URL: "/user-profile/api/v1/profile/internal/",
	VI_ANALYTICS_URL: "/api/vi-events/",
	CANDIDATE_DETAILS_URL: "/user-profile/api/v1/profile/me/enriched",
	CANDIDATE_JOB_DETAILS_URL: "/user-profile/api/v1/prefs?defaults=false",
	CANDIDATE_PROFILE_COMPLETION_PROGRESS_URL:
		"api/userprofile/v1/user/data-collection-progress/",
	APPLY_JOB_URL: "/api/v2/applied-jobs/",
	CANDIDATE_JOB_FEED_URL: "/user-profile-orchestrator/v1/jobs/",
	CANDIDATE_ELIGIBILITY_URL: "/api/jobfeed/v1/get_job_user_match_score/",
	REGISTER_JOB_APPLICATION_ON_WEB_URL: "/user-profile/api/v1/profile/me/job-applied",
	CANDIDATE_ONBOARDING_COMPLETE_URL: "/api/userprofile/v1/user/onboarding-completed/",
	CITY_AREA_URL: "/api/city-area",
	VERIFY_EMAIL: "/user-profile/api/v1/verify/token",

	// PROFILE EDIT ADD URLS
	JOB_TITLE_SUGGESTER:
		"/suggester/api/v2/suggestions?input={keyword}&types=JobTitle&size=5",
	COMPANY_NAME_SUGGESTER:
		"/apna-organisations/api/v2/organisation-search?keyword={keyword}&limit=5",
	GET_COLLEGE_NAMES:
		"/apna-organisations/api/v1/organisation-search?limit=15&is_educational=yes&keyword={keyword}",
	GET_DEGREE_LIST: "/api/edu/v1/course/",
	GET_SPECIALIZATION: "/api/edu/v1/specialization",
	EDIT_PROFILE: "user-profile/api/v1/profile/me/enriched",
	EDIT_EXPERIENCE:
		"user-profile/api/v1/profile/extended-experience/{experienceId}/enriched",
	ADD_EXPERIENCE: "user-profile/api/v2/profile/extended-experience/enriched",
	DELETE_EXPERIENCE: "/user-profile/api/v1/profile/me/experience/{experienceId}",
	GET_NORMALISED_JOB_TITLE:
		"suggester/api/v1/normalizer/JobTitle/{searchKeyword}?size=1&prefix_length=2",
	GET_SUB_DEPARTMENTS_BY_TITLE:
		"/user-profile/api/v1/profile/me/subDepartments/recommended",
	GET_EMPLOYER_SUB_DEPARTMENTS_BY_TITLE:
		"api/employer/v3/job-title-department",
	GET_DEPARTMENT_SUB_DEPARTMENT_SUGGESTIONS:
		"profile-entity/api/v1/sub-department/search?keyword={input}&limit=15",
	GET_INDUSTRY_BY_COMPANY: "/apna-organisations/api/v1/organisation/{orgId}",
	GET_INDUSTRY_WITH_SECTOR: "/apna-organisations/api/v1/sector-industries/",
	DEPARTMENT_SUGGESTER:
		"/api/employer/get_suggested_candidate_department?input={keyword}",
	ADD_EDUCATION: "/user-profile/api/v1/profile/me/education",
	EDIT_EDUCATION: "/user-profile/api/v1/profile/me/education/{educationId}",
	DELETE_EDUCATION: "/user-profile/api/v1/profile/me/education/{educationId}",
	PREFERRED_JOB_TYPES_V2_ENABLED: "/api/v1/blind-hr-configuration",
	EDIT_LANGUAGES_KNOWN: "/user-profile/api/v1/profile/me/bulk/language-fluency",
	API_ENRICHED_PROFILE: "user-profile/api/v1/profile/me/enriched",
	VERIFY_EMAIL_OTP_REQUEST: "user-profile/api/v1/request-verification",
	VERIFY_EMAIL_OTP: "user-profile/api/v1/verify",
	GET_JOB_CITIES: "/api/location/v1/cities/",
	GET_SUGGESTED_CITIES: `api/location/v1/cities/{cityId}/suggested-cities/`,
	WORK_TYPE_PREFERENCES: "/user-profile/api/v1/prefs/me/job-prefs",
	GET_CITY_LIVE_NON_LIVE_SUGGESTIONS:
		"suggester/api/v3/suggestions?types=City&size=100&input=",
	GET_AREA_SUGGESTIONS: `suggester/api/v3/suggestions?input=%SEARCH_INPUT%&types=Area&size=100&filters={"city_id":%CITY_ID%}`,
	GET_NEAREST_LOCATION: "/apna-geolocation/v2/nearest-area/",
	UPDATE_LOCATION: "/user-profile/api/v1/aggregate/locations",
	HoMETOWN_SUGGESTIONS:
		"/suggester/api/v2/suggestions?input={keyword}&size=10&types=Hometown",
	GET_CANDIDATE_DEPARTMENT: "/api/employer/get_candidate_department",
	SEARCH_CANDIDATE_DEPARTMENT:
		"/api/employer/get_suggested_candidate_department?input={keyword}",
	UPDATE_CANDIDATE_DEPARTMENT: "/user-profile/api/v1/prefs/me/job-types/enriched",
	GET_DOCUMENT_ASSET_QUESTIONS: "/user-profile/api/v1/profile/me/assets-docs",
	GET_UPLOAD_URL: "/user-profile/api/v1/profile/me/doc/{key}",
	LOCATION_SEGGESTER:
		"/suggester/suggester/api/v1/location/suggestions?input={searchKeyword}",
	SEARCH_SUGGESTER: "/suggester/suggester/api/v1/suggestions?input={searchKeyword}",
	EXPERIENCE_SUGGESTER: "/suggester/suggester/api/v1/experience/suggestions",
	POPULAR_AND_RECENT_TERMS: "/jobsearch/term/api/v1/recent-popular-terms?",
	GET_SKILLS_SUGGESTIONS:
		"/suggester/api/v2/suggestions?input={searchText}&size=5&types=ProfileSkill",
	GET_TOP_RECOMMENDED_SKILLS: "/user-profile/api/v2/profile/me/skills/recommended",
	UPDATE_SKILLS_TO_PROFILE: "/user-profile/api/v1/profile/me/skills-bulk",
	SEARCH_JOB_TITLE:
		"api/employer/v1/entities/search-filtered?input={INPUT}&types=JobTitle,SubDepartment",
	GET_TOP_RECOMMENDED_JOB_TITLE: "/api/v2/prefs/me/job-types/recommendations",
	UPDATE_JOB_TITLE: "/user-profile/api/v2/prefs/me/job-types/enriched",
	GET_LANGUAGE_LIST: "/user-profile/api/v1/profile/language-fluency-list",
	GET_ENGLISH_EVALUATION_SOURCE:
		"user-profile/api/v1/profile/me/evaluation/en/speak/1/?source={param}",
	// NB Location Edit:
	GET_NB_LOCATION_FROM_COORDS: "/location-service/api/area-by-coordinates/",
	GET_NB_CITY_AREA_SUGGESTIONS: "/location-service/api/area-suggestions",

	// OTP
	LOGIN_REQUEST_OTP: "/api/userprofile/v1/otp/",
	VERIFY_OTP: "/apna-auth/v2/otp/login/",

	POST_EXTERNAL_JOB_APPLIED_FEEDBACK: "/api/v1/nonapna-applied-job/",

	// Meta Data
	META_DATA: "/api/jobfeed/v1/get-apna-meta-details",
	META_DATA_V2: "/api/jobfeed/v2/get-apna-meta-details",
	// Web configs
	WEB_CONFIG: "/user-profile-orchestrator/v1/web-config",
	CANDIDATE_CONFIG: "/api/v1/configuration",
	SILICON_REGISTER: "campus-pulse/api/events/%EVENT_ID%/register",
	SILICON_REGISTERATION_STATUS: "campus-pulse/api/events/%EVENT_ID%/check-registration",
	ECC_SUCCESS_SCREEN: "api/jobfeed/v2/ecc/screen-details?job_id=",
	USER_JOURNEY_BE_CUSTOM_EVENT_PUBLISHER: "/apna-channels/api/v1/events/publish",

	// Canvas
	GET_CANAVAS_EVENT: "/apna-canvas/api/v1/challenges/%SLUG%",
	GET_CANVAS_CHALLENGE_HIGHLIGHTS:
		"/apna-canvas/api/v1/challenges/%ID%/challenge-status",
	GET_CANVAS_CHALLENGE_ROUND_STATES:
		"/apna-canvas/api/v1/challenges/%ID%/user-round-details",
	GET_CANVAS_CHALLENGE_LEARNING_HUB_STATES:
		"/apna-canvas/api/v1/challenges/%ID%/learning-hub-details",
	REGISTER_CANVAS_EVENT: "/apna-canvas/api/v1/challenges/register",
	CANVAS_CUSTOM_EVENT_PUBLISHER: "/apna-canvas/api/v1/events/publish",
	GET_ROUND_REDIRECTION_LINK:
		"/apna-canvas/api/v1/challenges/%ID%/test-sso-url?round_id=%ROUNDID%",
	CANVAS_CREAT_TEAM: "/apna-canvas/api/v1/challenges/%ID%/create-team",
	CANVAS_JOIN_TEAM: "/apna-canvas/api/v1/challenges/%ID%/join-team",
	GET_CANVAS_TEAM_DETAILS: "/apna-canvas/api/v1/challenges/%ID%/team-details",
	GET_CANVAS_AVAILABLE_TEAMS:
		"/apna-canvas/api/v1/challenges/%ID%/join-team-suggestions",
	GET_CANVAS_AVAILABLE_INDIVIDUALS:
		"/apna-canvas/api/v1/challenges/%ID%/team-member-suggestions",
	CANVAS_LEAVE_TEAM: "/apna-canvas/api/v1/challenges/%ID%/leave-team",
	CANVAS_CONTEST_TABS: "/apna-canvas/api/v1/challenges/tabs",
	CANVAS_CONTEST_LIST: "/apna-canvas/api/v1/challenges/all",
	DELETE_PROFILE: "/api/userprofile/v1/delete-user/{%TOKEN%}",
	MEDIUM_OF_EDUCATION: "/api/v1/languages",
	GET_SKILL_CERTIFICATE: "/profile-entity/api/v1/certification/search?keyword=%KEY%",
	UPDATE_SKILL_CERTIFICATE: "/user-profile/api/v1/profile/me/certification",
	CASE_STUDY_ROUND: "/apna-canvas/api/v1/challenges/%SLUG%/casestudy-round?round_id=2",
	CASE_STUDY_SUBMIT:
		"/apna-canvas/api/v1/challenges/%SLUG%/submit-casestudy-round?round_id=2",
	VIDEO_SUBMISSION:
		"/apna-canvas/api/v1/challenges/%SLUG%/upload-file-round?round_id=1",
	ROUND_ATTEMP_SUCCESS:
		"/apna-canvas/api/v1/challenges/%ID%/round-attempt-successful?round_id=%ROUNDID%",
	TAB_CLICKED: "/api/v1/bottom-tabs/",
};
