import { finishActivity } from "utils/app-bridge";

export interface UserAuthData {
	userPhoneNumber: string;
	userAccessToken: string;
}

export const isMobile = (): boolean => {
	if (typeof window !== "undefined") {
		return window.innerWidth <= 768; // 768px is a common mobile breakpoint
	}
	return false; // Default to desktop if window is undefined (server-side rendering)
};
export const isWebView = (query: { [key: string]: any }): boolean =>
	query.client === "android";

export const getStringFromSessionStorage = (key: string): string | null => {
	if (typeof window !== "undefined") {
		const value = sessionStorage.getItem(key);
		return value ? value : null;
	}
	return null; // Return null if window is undefined (server-side rendering)
};
export const getStringFromLocalStorage = (key: string): string | null => {
	if (typeof window !== "undefined") {
		const value = localStorage.getItem(key);
		return value ? value : null;
	}
	return null; // Return null if window is undefined (server-side rendering)
};

export function handleBackPressListener(currentHandlers = []) {
	const currentPath = window.location.pathname;

	// Priority check for the /learn/degree path
	if (currentPath === "/learn/degree") {
		finishActivity(); // Closes the WebView
		return;
	}

	for (let { condition, action } of currentHandlers) {
		if (condition()) {
			action();
			return true;
		}
	}

	window.history.back();
}

export const isApnaLearnPage = (pathname) => ["/learn/degree"].includes(pathname);
