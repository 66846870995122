/* eslint-disable import/prefer-default-export */
import QueriesKeys from "constants/queryKeys";
import { useQuery } from "react-query";
import { isUserLoggedIn } from "utils";
import { getCandidateConfiguration } from "utils/apiClientPrivate";

export const useNewLabelConfig = () =>
	useQuery<{
		isApnaLearnNewLabelShown: boolean;
		isApnaCanvasNewLabelShown: boolean;
	}>(
		QueriesKeys.GET_NEW_LABEL_CONFIG,
		async () => {
			try {
				// Check if the user is logged in
				if (!isUserLoggedIn()) {
					return {
						isApnaLearnNewLabelShown: true, // Default to showing if not logged in
						isApnaCanvasNewLabelShown: true,
					};
				}

				// Fetch candidate configuration data
				const { data } = await getCandidateConfiguration();

				// Return configuration based on response
				return {
					isApnaLearnNewLabelShown:
						data?.bottom_tab_config?.apna_learn_config?.show_new_label ??
						false,
					isApnaCanvasNewLabelShown:
						data?.bottom_tab_config?.apna_canvas_config?.show_new_label ??
						false,
				};
			} catch {
				// If there's an error, default to hiding the label
				return {
					isApnaLearnNewLabelShown: false,
					isApnaCanvasNewLabelShown: false,
				};
			}
		},
		{
			enabled: true,
			/**
			 * We are setting stale time to 0 for logged out users and 5 mins for logged in users.
			 * This is done to ensure that the new label is shown immediately for logged out users and
			 * for logged in users we delay the stale time so that we can revalidate the data after login and during reloads.
			 */
			staleTime: !isUserLoggedIn() ? 0 : 5 * 60 * 1000,
		}
	);
