/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CircularProgress } from "@material-ui/core";
import CommunityNavbarIcon from "assets/svgs/CommunitiesIcon";
import CommunityNavbarFillIcon from "assets/svgs/CommunityNavbarFillIcon";
import DegreeNavBarFilled from "assets/svgs/DegreeNavbarFilled";
import DegreeNavbarIcon from "assets/svgs/DegreeNavbarIcon";
import HomeNavbarFillIcon from "assets/svgs/HomeNavbarFillIcon";
import HomeNavbarIcon from "assets/svgs/HomeNavbarIcon";
import JobsNavbarFillIcon from "assets/svgs/JobsNavbarFillIcon";
import JobsNavbarIcon from "assets/svgs/JobsNavbarIcon";
import NewTagComponent from "assets/svgs/NewTagComponent";
import clsx from "clsx";
import UserAvatar from "components/UserAvatar";
import QueriesKeys from "constants/queryKeys";
import { useNewLabelConfig } from "hooks/useNewLabelConfig";
import { SECTION_MAP } from "modules/candidate/profile/constants/sectionConstants";
import { useCandidateDetails } from "modules/candidate/profile/hooks/useCandidateDetails";
import { useCandidateProfile } from "modules/candidate/profile/hooks/useCandidateProfile";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { getUserId, isUserLoggedIn } from "utils";
import { queryClient, tabClicked } from "utils/apiClientPrivate";
import Mixpanel from "utils/Mixpanel";

const NAVBAR_PAGES = {
	HOME: "Home",
	JOBS: "Jobs",
	COMMUNITIES: "Communities",
	LEARN: "Learn",
	PROFILE: "Profile",
};
const MobileNavbar = () => {
	const router = useRouter();
	const [currentPage, setCurrentPage] = useState("Jobs");

	const { data: candidateDetails, isLoading: isProfileDetailLoading } =
		useCandidateProfile();
	const { data: profileDetails, isLoading: isCandidateDetailLoading } =
		useCandidateDetails(candidateDetails?.phoneNumber);
	const isFresher = !profileDetails?.[SECTION_MAP.PROFILE_EDITOR]?.isExperienced;

	const { data: newLabelData } = useNewLabelConfig();

	useEffect(() => {
		if (router.pathname === "/candidate/jobs") setCurrentPage(NAVBAR_PAGES.JOBS);
		if (router.pathname === "/community") setCurrentPage(NAVBAR_PAGES.COMMUNITIES);
		if (router.pathname === "/candidate/profile")
			setCurrentPage(NAVBAR_PAGES.PROFILE);
		if (router.pathname === "/home") setCurrentPage(NAVBAR_PAGES.HOME);
		if (router.pathname === "/learn/degree") setCurrentPage(NAVBAR_PAGES.LEARN);
	}, [router.pathname]);

	const handleChange = (value) => {
		setCurrentPage(value);
	};
	if (isProfileDetailLoading || isCandidateDetailLoading) {
		return (
			<div className="fixed bottom-0 left-0 z-50 flex w-full items-center justify-center border border-t-[#E8E7EA] bg-white p-[16px] text-[#1F8268] shadow-100 md:hidden">
				<CircularProgress color="inherit" size={30} />
			</div>
		);
	}
	return (
		<div className="fixed bottom-0 left-0 z-50 w-full border border-t-[#E8E7EA] bg-white p-[8px] pt-0 shadow-100 md:hidden">
			<div className="flex justify-center">
				{!isProfileDetailLoading && !isCandidateDetailLoading && isFresher && (
					<div
						className={clsx(
							"relative flex w-full min-w-[83px] max-w-[112px]  flex-col items-center space-y-[8px] pt-[8px]",
							{
								"before:absolute before:left-0 before:top-0 before:h-[2px] before:w-full before:bg-[#1F8268]":
									currentPage === NAVBAR_PAGES.HOME,
							}
						)}
						onClick={() => {
							window.open("/home", "_self");
							handleChange(NAVBAR_PAGES.HOME);
						}}
					>
						{currentPage === NAVBAR_PAGES.HOME ? (
							<HomeNavbarFillIcon />
						) : (
							<HomeNavbarIcon />
						)}
						<p
							className={clsx("m-0 text-[12px] leading-[16px]", {
								"font-semibold text-[#1F8268]":
									currentPage === NAVBAR_PAGES.HOME,
								"text-[#8C8594]": currentPage !== NAVBAR_PAGES.HOME,
							})}
						>
							{NAVBAR_PAGES.HOME}
						</p>
					</div>
				)}
				<div
					className={clsx(
						"relative flex w-full min-w-[83px] max-w-[112px]  flex-col items-center space-y-[8px] pt-[8px]",
						{
							"before:absolute before:left-0 before:top-0 before:h-[2px] before:w-full before:bg-[#1F8268]":
								currentPage === NAVBAR_PAGES.JOBS,
						}
					)}
					onClick={() => {
						window.open("/jobs", "_self");
						handleChange(NAVBAR_PAGES.JOBS);
					}}
				>
					{currentPage === NAVBAR_PAGES.JOBS ? (
						<JobsNavbarFillIcon />
					) : (
						<JobsNavbarIcon />
					)}
					<p
						className={clsx("m-0 text-[12px] leading-[16px]", {
							"font-semibold text-[#1F8268]":
								currentPage === NAVBAR_PAGES.JOBS,
							"text-[#8C8594]": currentPage !== NAVBAR_PAGES.JOBS,
						})}
					>
						{NAVBAR_PAGES.JOBS}
					</p>
				</div>
				<div
					className={clsx(
						"relative flex w-full min-w-[83px] max-w-[112px]  flex-col items-center space-y-[8px] pt-[8px]",
						{
							"before:absolute before:left-0 before:top-0 before:h-[2px] before:w-full before:bg-[#1F8268]":
								currentPage === NAVBAR_PAGES.LEARN,
						}
					)}
					onClick={() => {
						Mixpanel.track("Learn now button Clicked", {
							name: profileDetails?.[SECTION_MAP.OVERALL]
								?.profileCardDetails?.name,
							UserPlatform: "web",
							web_logged_in: isUserLoggedIn() ? "yes" : "no",
							device: "Mobile",
							UserUID: getUserId(),
							phone: candidateDetails?.phoneNumber,
						});
						window.open("/learn/degree?utm_source=Web_apna", "_self");
						handleChange(NAVBAR_PAGES.LEARN);
					}}
				>
					<div>
						<div className="relative inline-flex flex-col items-center text-center">
							<div className="relative">
								{!!newLabelData?.isApnaLearnNewLabelShown && (
									<div className="absolute right-2.5 top-1 -translate-y-1/2 translate-x-3/4 transform">
										<NewTagComponent />
									</div>
								)}
								{currentPage === NAVBAR_PAGES.LEARN ? (
									<DegreeNavBarFilled />
								) : (
									<DegreeNavbarIcon />
								)}
							</div>
							<p
								className={clsx("m-0 mt-2  text-[12px] leading-[16px]", {
									"font-semibold text-[#1F8268]":
										currentPage === NAVBAR_PAGES.LEARN,
									"text-[#8C8594]": currentPage !== NAVBAR_PAGES.LEARN,
								})}
							>
								Degree
							</p>
						</div>
					</div>
				</div>
				{/**
				 * Part of community removal
				 */}
				{false && (
					<div
						className={clsx(
							"relative flex w-full min-w-[83px] max-w-[112px]  flex-col items-center space-y-[8px] pt-[8px]",
							{
								"before:absolute before:left-0 before:top-0 before:h-[2px] before:w-full before:bg-[#1F8268]":
									currentPage === NAVBAR_PAGES.COMMUNITIES,
							}
						)}
						onClick={() => {
							window.open("/community", "_self");
							handleChange(NAVBAR_PAGES.COMMUNITIES);
						}}
					>
						{currentPage === NAVBAR_PAGES.COMMUNITIES ? (
							<CommunityNavbarFillIcon />
						) : (
							<CommunityNavbarIcon />
						)}

						<p
							className={clsx("m-0 text-[12px] leading-[16px]", {
								"font-semibold text-[#1F8268]":
									currentPage === NAVBAR_PAGES.COMMUNITIES,
								"text-[#8C8594]":
									currentPage !== NAVBAR_PAGES.COMMUNITIES,
							})}
						>
							{NAVBAR_PAGES.COMMUNITIES}
						</p>
					</div>
				)}
				<div
					className={clsx(
						"relative flex w-full min-w-[83px] max-w-[112px]  flex-col items-center space-y-[8px] pt-[8px]",
						{
							"before:absolute before:left-0 before:top-0 before:h-[2px] before:w-full before:bg-[#1F8268]":
								currentPage === NAVBAR_PAGES.PROFILE,
						}
					)}
					onClick={() => {
						window.open("/candidate/profile", "_self");
						handleChange(NAVBAR_PAGES.PROFILE);
					}}
				>
					<div className="h-[24px] w-[24px]">
						<UserAvatar
							showOnlyAvatar
							showOutline={currentPage === NAVBAR_PAGES.PROFILE}
						/>
					</div>
					<p
						className={clsx("m-0 text-[12px] leading-[16px]", {
							"font-semibold text-[#1F8268]":
								currentPage === NAVBAR_PAGES.PROFILE,
							"text-[#8C8594]": currentPage !== NAVBAR_PAGES.PROFILE,
						})}
					>
						{NAVBAR_PAGES.PROFILE}
					</p>
				</div>
			</div>
		</div>
	);
};

export default MobileNavbar;
