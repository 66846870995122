/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import "../styles/globals.css";
import {
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import Head from "next/head";
import * as Sentry from "@sentry/nextjs";
import styled, { createGlobalStyle } from "styled-components";
// import TagManager from "react-gtm-module";
// import { datadogRum } from "@datadog/browser-rum";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider } from "react-query";
import { CircularProgress, Snackbar } from "@material-ui/core";
import useIsDesktop from "hooks/useIsDesktop";
import uuidv4 from "utils/uuid";
import { useRouter } from "next/router";
import { isHostLaunchedMicrosite, isRHRUser, isUserLoggedIn } from "utils";
import Axios from "axios";
import useSafePush from "hooks/useSafePush";
import { getCandidateOnboardingComplete, queryClient } from "utils/apiClientPrivate";
import { LOCAL_STORAGE_KEYS, MICROSITES, SESSION_STORAGE_KEYS } from "utils/constants";
import Toast from "components/ToastComponent";
import { ToastContextProvider } from "context/Toast/ToastContext";
import QueriesKeys from "constants/queryKeys";
import MobileNavbar from "components/MobileNavbar";
import clsx from "clsx";
import { getAppUserData, hideProgressBar } from "utils/app-bridge";
import { isWebView } from "modules/apnaLearn/utils/helpers";
import { trackOnMixpanel } from "modules/candidate/profile/utils/MixpanelHelper";
import { LEARN_USER_DATA } from "modules/apnaLearn/utils/constants";
import config from "../config";
import { CSSResets } from "../styles/reset";
import Clevertap from "../libs/Clevertap";
import "styles/google-fonts.css";
import "styles/vi-fonts.css";
import "styles/svipStyle.css";
import GtmInitializer from "./GTMInitializer";
import { LoginRefactoredContextProvider } from "../components/LoginRefactored/LoginContext";

// datadogRum.init({
// 	applicationId: "ade82a42-c7ee-40d4-a799-0655f53d59a4",
// 	clientToken: "pubc0e0c2ba77b8b4b1c910201ded4f885e",
// 	site: "datadoghq.com",
// 	service: "apna.co",
// 	env: config.datadogEnv,
// 	// Specify a version number to identify the deployed version of your application in Datadog
// 	// version: '1.0.0',
// 	sampleRate: 40,
// 	trackInteractions: true,
// });

const GlobalStyles = createGlobalStyle`
    ${CSSResets};
	
	.ReactModal__Body--open {
		overflow: hidden;
		height: 100vh;
	}

	.ReactModal__Overlay--after-open {
		z-index: 2000 !important;
	}
`;

function Loading() {
	const router = useRouter();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const handleRouteChangeStart = () => {
			console.log("Start of Route");
			setLoading(true);
			// eslint-disable-next-line no-multi-assign
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			document.body.style.overflow = "hidden";
		};

		const handleRouteChangeEnd = () => {
			console.log("End of Route");
			setTimeout(() => {
				setLoading(false);
				document.body.style.overflow = "auto";
			}, 10);
		};

		router.events.on("routeChangeStart", handleRouteChangeStart);
		router.events.on("routeChangeComplete", handleRouteChangeEnd);
		router.events.on("routeChangeError", handleRouteChangeEnd);

		return () => {
			router.events.off("routeChangeStart", handleRouteChangeStart);
			router.events.off("routeChangeComplete", handleRouteChangeEnd);
			router.events.off("routeChangeError", handleRouteChangeEnd);
		};
	}, []);

	return (
		loading && (
			<LoadingConatiner className="fixed z-[1000] flex h-[100vh] w-[100vw] items-center justify-center bg-white">
				<CircularProgress color="error" />
			</LoadingConatiner>
		)
	);
}

function MyApp({ Component, pageProps }) {
	const router = useRouter();
	const isDesktopView = useIsDesktop();
	const [shouldShowLoader, setShouldShowLoader] = useState(false);
	const [isLoginToastOpen, setIsLoginToastOpen] = useState(!!isUserLoggedIn());
	const [isEnrichedLoginSuucess, setIsEnrichedLoginSuucess] = useState(false);
	const [showMobileNavbar, setShowMobileNavbar] = useState(false);
	const hasAuthBeenCalled = useRef(false);

	const throughWebView = isWebView(router.query);

	useEffect(() => {
		const appUserData = getAppUserData();
		if (appUserData?.id) {
			sessionStorage.setItem(LEARN_USER_DATA, JSON.stringify(appUserData));
			localStorage.setItem(
				LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
				appUserData?.accessToken
			);
		}
		hideProgressBar();
	}, []);

	const showLoginToast = useMemo(
		() => isLoginToastOpen && isEnrichedLoginSuucess,
		[isEnrichedLoginSuucess, isLoginToastOpen]
	);
	const onLoginToastClose = useCallback(() => {
		setIsLoginToastOpen(false);
		setIsEnrichedLoginSuucess(false);
	}, [setIsLoginToastOpen, setIsEnrichedLoginSuucess]);

	const { safePush } = useSafePush();

	useEffect(() => {
		// TagManager.initialize({
		// 	gtmId: config.google.tagManager,
		// 	dataLayerName: "PageDataLayer",
		// });

		const initializeCleverTap = () => {
			if (document.readyState === "complete") {
				Clevertap.initialize(config.cleverTapAccountId);
			}
		};
		// eslint-disable-next-line func-names
		document.onreadystatechange = initializeCleverTap;

		sessionStorage.setItem("LANDED_ON_APNA", true);

		import("../utils/Mixpanel").then((Mixpanel) => {
			if (!localStorage.getItem("web_user_id")) {
				Mixpanel.default.identify();
				const uuid = uuidv4();
				localStorage.setItem("web_user_id", uuid);
				Mixpanel.default.people.set({
					web_user_id: uuid,
				});
			}
			if (localStorage.getItem("user_id")) {
				Mixpanel.default.identify(localStorage.getItem("user_id"));
			}
		});

		return () => {
			document.removeEventListener("readystatechange", initializeCleverTap);
		};
	}, []);
	useLayoutEffect(() => {
		const getReturnToLink = () => {
			if (
				Object.keys(router.query).find(
					(queryKey) => queryKey === "profile_interaction"
				)
			) {
				return `/candidate/profile?profile_interaction=${router.query.profile_interaction}&set_experienced=true`;
			}

			if (router.pathname === "/candidate/jobs/[[...paths]]") {
				return window.location.pathname;
			}

			if (router.pathname === "/job/[...job]") {
				return window.location.pathname;
			}
			return "";
		};

		if (router?.query?._branch_referrer || router.query?._branch_match_id) {
			if (!window.branch && config.branchIO) {
				setShouldShowLoader(true);
				const script = document.createElement("script");
				script.src = "https://cdn.branch.io/branch-latest.min.js";
				script.async = false;
				script.onload = () => {
					window.branch?.init(config.branchIO, async (error, data) => {
						if (error || !data?.data_parsed) {
							console.error("Branch Data Parsing Failure: ", error);
							safePush({
								query: {
									user_auth: "phone_number",
									returnTo: getReturnToLink(),
								},
							});
						}
						if (data?.data_parsed?.token) {
							if (data?.data_parsed?.["~source"]) {
								sessionStorage.setItem(
									SESSION_STORAGE_KEYS.UTM_SOURCE,
									data?.data_parsed?.["~source"]
								);
							}
							if (!hasAuthBeenCalled.current) {
								const authResponse = await Axios.post(
									`${config.baseUrl}/api/employer/v2/auth_token/`,
									{
										token: data?.data_parsed?.token,
										evict_token: false,
									}
								);
								if (authResponse.data?.tokens?.access) {
									hasAuthBeenCalled.current = true;
									const userID = authResponse.data?.user_data?.id;
									const accessToken = authResponse.data?.tokens?.access;
									localStorage.setItem(
										LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
										accessToken
									);
									localStorage.setItem(
										LOCAL_STORAGE_KEYS.USER_ID,
										userID
									);

									import("../utils/Mixpanel").then((Mixpanel) => {
										Mixpanel.default.identify(userID);
									});
									const enrichmentStatusResponse =
										await getCandidateOnboardingComplete();
									trackOnMixpanel("Web onboarding login success", {
										login_type: "auto",
										// eslint-disable-next-line no-nested-ternary
										userType: enrichmentStatusResponse.data
											?.is_profile_complete
											? "old enriched user"
											: enrichmentStatusResponse.data
													?.profile_type === "new"
											? "new user"
											: "old non enriched user",
									});

									/**
									 * Remove the branch query params from the URL. If this is not done
									 * this will hamper with other redirections that are in place
									 */
									const queryParams = Object.keys(router.query).reduce(
										(acc, key) => {
											if (!key.startsWith("_branch")) {
												acc[key] = router.query[key];
											}
											return acc;
										},
										{}
									);
									/**
									 * If it's a candidate feed route, add c_id to the query params, so as to
									 * avoid internal redirections to put the access token in the URL.
									 */
									safePush({
										pathname: router.asPath.includes("?")
											? router.asPath?.split("?")?.[0]
											: router.asPath,
										query: {
											...(router.pathname ===
												"/candidate/jobs/[[...paths]]" && {
												c_id: accessToken,
											}),
											...queryParams,
										},
									});
								}
								if (
									!authResponse.data?.tokens?.access &&
									!hasAuthBeenCalled.current
								) {
									if (isUserLoggedIn()) {
										if (data?.data_parsed?.job_id) {
											safePush(`/job/${data?.data_parsed?.job_id}`);
										}
										setShouldShowLoader(false);

										return;
									}
									safePush({
										pathname: router.asPath.includes("?")
											? router.asPath?.split("?")?.[0]
											: router.asPath,
										query: {
											user_auth: "phone_number",
											returnTo: getReturnToLink(),
										},
									});
								}
							}
						}
						if (!data?.data_parsed?.token && data?.data_parsed?.job_id) {
							safePush(`/job/${data?.data_parsed?.job_id}`);
						}
						setShouldShowLoader(false);
					});
				};
				document.head.appendChild(script);
			}
		}
	}, [router.asPath, router.query, router.pathname]);
	useLayoutEffect(() => {
		const handleRouterChanges = () => {
			/**
			 * We don't want the following redirections to work in case branch ids are present
			 * which are supposed to facilitate the auto-login.
			 */
			if (!router?.query?._branch_referrer && !router.query?._branch_match_id) {
				/**
				 * In case user is trying to access the job detail page, and he is
				 * coming through VI we will add the source as query param.
				 */
				if (
					router.pathname === "/job/[...job]" &&
					isHostLaunchedMicrosite() &&
					!router.query?.source
				) {
					safePush({
						pathname: router.asPath?.split("?")?.[0],
						query: { source: "vi" },
					});
				}
				/**
				 * In case user is trying to access the job detail page, and he is
				 * logged in but c_id is not in query param, then we add c_id as query param.
				 */
				if (
					router.pathname === "/job/[...job]" &&
					isUserLoggedIn() &&
					!router.query?.c_id
				) {
					const isJobApplicationInProgress = sessionStorage.getItem(
						"jobApplicationBeforeLoginTriggered"
					);
					const isAuthFromJDPInProgressFromStore = sessionStorage.getItem(
						"authFromJDPInProgress"
					);
					const isAuthFromJDPInProgress = isAuthFromJDPInProgressFromStore
						? JSON.parse(isAuthFromJDPInProgressFromStore)
						: false;
					if (!isJobApplicationInProgress || !isAuthFromJDPInProgress) {
						safePush({
							pathname: router.asPath?.split("?")?.[0],
							query: { c_id: isUserLoggedIn() },
						});
					} else {
						sessionStorage.removeItem("authFromJDPInProgress");
					}
				}
				/**
				 * In case it's a microsite and user is trying to somehow land on
				 * apna landing page, we redirect him back to personalised feed.
				 */
				if (
					router.pathname === "/" &&
					isHostLaunchedMicrosite() &&
					isUserLoggedIn()
				) {
					safePush({
						pathname: "/candidate/jobs",
						query: { c_id: isUserLoggedIn() },
					});
				}
				/**
				 * If anyone tries to visit the job feed when user is logged in,
				 * we should automatically redirect to candidate feed with same query params
				 */
				if (router.pathname === "/jobs/[[...paths]]" && isUserLoggedIn()) {
					const queryObj = { ...router.query, c_id: isUserLoggedIn() };

					if (isRHRUser()) {
						queryObj.user_type = "rhr";
					}
					safePush({
						pathname: router.asPath.includes("?")
							? `/candidate${router.asPath?.split("?")?.[0]}`
							: `/candidate${router.asPath}`,
						query: { ...queryObj },
					});
				}
				/**
				 * If user somehow lands on the candidate job listing page, and he's not logged in
				 * redirect him to public listing page instead
				 */
				if (
					router.pathname === "/candidate/jobs/[[...paths]]" &&
					!isUserLoggedIn()
				) {
					safePush({
						pathname: "/jobs",
					});
				}
				/**
				 * If user somehow lands in the candidate feed page, if he's logged in
				 * attach the access_token as a query parameter, so that candidate feed page
				 * can render it's content on server side itself.
				 *
				 * If user lands on the international job pages, and is a logged in user, we
				 * add the candidate's access token to the path
				 */
				if (
					["/candidate/jobs/[[...paths]]", "/international/jobs"].includes(
						router.pathname
					) &&
					((isUserLoggedIn() && !router.query?.c_id) ||
						(isHostLaunchedMicrosite() && !router.query?.source))
				) {
					const queryObj = { ...router.query, c_id: isUserLoggedIn() };
					if (isHostLaunchedMicrosite()) {
						queryObj.source = "vi";
					}
					safePush({
						pathname: router.asPath.includes("?")
							? router.asPath?.split("?")?.[0]
							: router.asPath,
						query: queryObj,
					});
				}
				// for Bento Fresher Page user can only see the page if logged in
				if (router.pathname === "/home" && !isUserLoggedIn()) {
					safePush({
						pathname: "/",
					});
				}
			}
		};

		handleRouterChanges();
	}, [router.query, router.asPath, router.pathname]);

	useEffect(() => {
		if (router.query?.enriched_login) {
			setIsEnrichedLoginSuucess(true);
			delete router.query.enriched_login;
			router.replace({
				pathname: router.pathname,
				query: router.query,
			});
		}
	}, [router.query]);

	// Candidate onboarding complete api call
	useEffect(() => {
		if (isUserLoggedIn()) {
			queryClient
				.fetchQuery(
					QueriesKeys.GET_CANDIDATE_ONBOARDING_COMPLETE,
					async () => {
						const { data } = await getCandidateOnboardingComplete();
						return {
							isCandidateOnboardingCompleted: data?.is_profile_complete,
						};
					},
					{
						retry: false,
					}
				)
				.then((res) => {
					localStorage.setItem(
						LOCAL_STORAGE_KEYS.IS_ONBOARDING_COMPLETE,
						res?.isCandidateOnboardingCompleted
					);
				})
				.catch((err) => console.error(err));
		}
	}, []);

	useLayoutEffect(() => {
		/**
		 * Check if any UTM parameters are available. Doing router replace
		 * without them will be inefficient
		 */
		const isUTMParameterAvailable = Object.keys(router.query)?.find((queryParam) =>
			queryParam.startsWith("utm")
		);
		if (isUTMParameterAvailable) {
			if (router.query?.utm_source) {
				sessionStorage.setItem(
					SESSION_STORAGE_KEYS.UTM_SOURCE,
					router.query?.utm_source
				);

				/**
				 * Custom Onboarding flow for RHR.
				 */
				if (router.query?.utm_source === "rhr") {
					sessionStorage.setItem(
						SESSION_STORAGE_KEYS.ONBOARDING_FLOWTYPE,
						router.query?.utm_source
					);
				}
			}
			if (router.query?.utm_medium) {
				sessionStorage.setItem(
					SESSION_STORAGE_KEYS.UTM_MEDIUM,
					router.query?.utm_medium
				);
			}
			if (router.query?.utm_campaign) {
				sessionStorage.setItem(
					SESSION_STORAGE_KEYS.UTM_CAMPAIGN,
					router.query?.utm_campaign
				);
			}
			if (router.query?.utm_id) {
				sessionStorage.setItem(SESSION_STORAGE_KEYS.UTM_ID, router.query?.utm_id);
			}
			if (router.query?.utm_term) {
				sessionStorage.setItem(
					SESSION_STORAGE_KEYS.UTM_TERM,
					router.query?.utm_term
				);
			}
			if (router.query?.utm_content) {
				sessionStorage.setItem(
					SESSION_STORAGE_KEYS.UTM_CONTENT,
					router.query?.utm_content
				);
			}
			// Remove query parameters starting with 'utm'
			const queryParams = Object.keys(router.query).reduce((acc, key) => {
				if (!key.startsWith("utm")) {
					acc[key] = router.query[key];
				}
				return acc;
			}, {});

			// Replace the current URL with the updated query parameters
			router.replace(
				{
					pathname: router.asPath.includes("?")
						? router.asPath?.split("?")?.[0]
						: router.asPath,
					query: queryParams,
				},
				undefined,
				{
					shallow: true,
				}
			);
		}
	}, [router.asPath, router.query]);

	useLayoutEffect(() => {
		document.body.style.fontFamily =
			isHostLaunchedMicrosite() === MICROSITES.VI ? "Vi" : "Inter";
	}, []);

	useEffect(() => {
		if (isUserLoggedIn()) {
			Axios.post(
				`${config.baseUrl}/api/v1/app-version?version=19&src=${
					isHostLaunchedMicrosite() ? "web_apnaco_vi" : "web_apnaco"
				}`,
				{},
				{
					headers: {
						Authorization: `Token ${isUserLoggedIn()}`,
					},
				}
			);
		}
	}, [isUserLoggedIn()]);

	useEffect(() => {
		const allowedRoutes = [
			"/home",
			"/candidate/jobs/[[...paths]]",
			"/community",
			"/candidate/profile",
			"/learn/degree",
		];

		const isProfileEditorOpen =
			router.pathname === "/candidate/profile" && router.query.profile_interaction;

		if (
			isUserLoggedIn() &&
			allowedRoutes.includes(router.pathname) &&
			!throughWebView &&
			!isProfileEditorOpen
		)
			setShowMobileNavbar(true);
		else setShowMobileNavbar(false);
	}, [router.pathname, isUserLoggedIn(), router.query.profile_interaction]);

	const getLayout = Component.getLayout || ((page) => page);
	return (
		<>
			<GtmInitializer />
			<Loading />
			<Head>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="preconnect" href="https://cdn.apna.co" />
				<link rel="preconnect" href="https://www.googletagmanager.com" />
				<link rel="preconnect" href="https://www.clarity.ms" />
				{/* <script
					dangerouslySetInnerHTML={{
						__html: ` window.GUMLET_CONFIG = {
							hosts: [{
								current: "cdn.apna.co",
								gumlet: "cdn.apna.co"
							}],
							lazy_load: true
						};
						(function(){d=document;s=d.createElement("script");s.src="https://cdn.gumlet.com/gumlet.js/2.0/gumlet.min.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
				`,
					}}
				/> */}
				<meta name="theme-color" content="#4d3951" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1"
				/>
			</Head>
			<GlobalStyles />
			<QueryClientProvider client={queryClient}>
				<Sentry.ErrorBoundary>
					<LoginRefactoredContextProvider>
						<ToastContextProvider>
							{shouldShowLoader ? (
								<div className="flex h-[100vh] w-full items-center justify-center">
									<CircularProgress
										style={{ marginBottom: "31px", color: "#1F8268" }}
									/>
								</div>
							) : (
								<>
									{getLayout(
										<div
											className={clsx("md:mb-0", {
												"mb-[66px]": showMobileNavbar,
											})}
										>
											<Component {...pageProps} />
										</div>
									)}
								</>
							)}
							<Snackbar
								open={showLoginToast}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: isDesktopView ? "right" : "center",
								}}
								autoHideDuration={5000}
								onClose={onLoginToastClose}
							>
								<Toast
									heading="Login Successful!"
									subHeading="Welcome back to your personalised job feed"
									onClose={onLoginToastClose}
								/>
							</Snackbar>
							{showMobileNavbar && <MobileNavbar />}
							<ReactQueryDevtools />
						</ToastContextProvider>
					</LoginRefactoredContextProvider>
				</Sentry.ErrorBoundary>
			</QueryClientProvider>
		</>
	);
}

export default MyApp;

const LoadingConatiner = styled.div`
	.MuiCircularProgress-root {
		.MuiCircularProgress-svg {
			color: #1f8268 !important;
		}
	}
`;
