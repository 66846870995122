import * as Sentry from "@sentry/nextjs";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.NEXT_PUBLIC_SENTRY_ENV || "production";

/**
 * Initialize Sentry for client-side error and performance monitoring
 * This configuration handles:
 * 1. Error tracking with proper stack traces
 * 2. Performance monitoring with browser tracing
 * 3. Console error capturing
 * 4. Environment-specific settings
 */
Sentry.init({
	dsn: SENTRY_DSN,
	environment: ENV,
	integrations: [
		new CaptureConsoleIntegration({
			levels: ["error"],
		}),
		...(typeof window !== "undefined"
			? [
					new Sentry.BrowserTracing({
						/**
						 * Control which URLs get performance tracing
						 * Only requests matching these patterns will have tracing headers
						 */
						tracePropagationTargets: [
							"localhost",
							/^https:\/\/apna\.co/, // Production domain
						],
					}),
			  ]
			: []),
	],

	/**
	 * Performance sampling rates
	 * Production: Lower rates to minimize bandwidth
	 * Other environments: Higher rates for debugging
	 */
	tracesSampleRate: ENV === "production" ? 0.1 : 1.0,
	sampleRate: 1.0,

	// Enable debug mode in non-production
	debug: ENV !== "production",

	// Don't send PII data
	sendDefaultPii: false,

	// Automatically instrument Next.js components
	autoSessionTracking: true,

	// Set maximum breadcrumbs
	maxBreadcrumbs: 50,
});
