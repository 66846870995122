import React from "react";

const PersonIcon = ({ fill = "#190A28"}) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8 3.425C8.87 3.425 9.575 4.13 9.575 5C9.575 5.87 8.87 6.575 8 6.575C7.13 6.575 6.425 5.87 6.425 5C6.425 4.13 7.13 3.425 8 3.425ZM8 10.175C10.2275 10.175 12.575 11.27 12.575 11.75V12.575H3.425V11.75C3.425 11.27 5.7725 10.175 8 10.175ZM8 2C6.3425 2 5 3.3425 5 5C5 6.6575 6.3425 8 8 8C9.6575 8 11 6.6575 11 5C11 3.3425 9.6575 2 8 2ZM8 8.75C5.9975 8.75 2 9.755 2 11.75V13.25C2 13.6625 2.3375 14 2.75 14H13.25C13.6625 14 14 13.6625 14 13.25V11.75C14 9.755 10.0025 8.75 8 8.75Z"
			fill={fill}
		/>
	</svg>
);

export default PersonIcon;
